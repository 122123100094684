<template>
    <div id="main-modal" class="modal hidden">
        <div class="modal-content">
            <div class="modal-title"></div>
            <div class="modal-message"></div>
            <div class="modal-actions">
                <button @click="closeModal()">D'accord</button>
            </div>
        </div>
    </div>

    <Navigation :navigations="pages">
    </Navigation>

    <transition name="fade">
        <router-view />
    </transition>

    <Footer></Footer>
</template>

<script>

import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'

const GalleryData = require('@/data/gallery.json');

export default {
    components: {
        Navigation,
        Footer
    },

    data: function () {
        return {
            pages: [
                {
                    text: 'Accueil',
                    target: 'Home'
                },
                {
                    text: 'Offres',
                    target: 'Offers',
                    clickable: false,
                    submenus: [
                        {
                            text: "Athlétisme",
                            target: "Athletism"
                        },
                        {
                            text: "Gym-Danse",
                            target: 'Gym-Dance'
                        },
                        {
                            text: "Gymnastique",
                            target: 'Gymnastics'
                        },
                    ]
                },

                {
                    text: "Galerie",
                    clickable: false,
                    submenus: this.getGallerySubmenus(),
                },
                {
                    text: 'Costumes', 
                    target: 'Costume' 
                },
                {
                    text: 'Charte',
                    target: 'Charter'
                },

                {
                    text: 'Adhésion',
                    target: 'Membership'
                },

                {
                    text: 'Contact',
                    target: 'Contact'
                },
            ]
        }
    },

    methods: {
        closeModal: function () {
            document.getElementById('main-modal').classList.add('hidden');
        },

        getGallerySubmenus: function () {
            let submenus = [];
            for (const event in GalleryData) {
                const eventData = GalleryData[event];
                submenus.push({
                    text: eventData.navText,
                    target: 'Galerie',
                    parameters: {
                        event: event
                    },
                    size: eventData.navTextSize
                });
            }
            return submenus;
        }
    }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

#main-modal {
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;

    &.hidden {
        display: none;
    }

    &>.modal-content {
        border: 1px solid #ccc;
        border-radius: 7px;
        padding: 30px;
        background: white;

        margin: 35% auto;
        max-width: 600px;
        text-align: left;

        &>.modal-title {
            font-size: 1.3rem;
        }

        &>.modal-message {
            padding: 20px;
        }

        &>.modal-actions {
            text-align: right;
        }
    }
}
</style>

<style>
* {
    box-sizing: border-box;
}

#app {
    font-family: Arial, Helvetica, 'Segoe-UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 17rem;
}

.no-drag {
    -moz-user-select: none;         /* Pour Firefox */
    -webkit-user-select: none;      /* Pour Safari et autres navigateurs WebKit */
    -ms-user-select: none;          /* Pour Internet Explorer/Edge */
    user-select: none;              /* Propriétés standards pour d'autres navigateurs */
    -webkit-user-drag: none;        /* Pour empêcher le drag des éléments dans Safari */
    user-drag: none;                /* Pour empêcher le drag des éléments dans certains navigateurs modernes */
    -webkit-touch-callout: none;    /* Pour empêcher l'apparition du menu contextuel sur les appareils tactiles */
}


@media (max-width: 1440px) {
    #app {
        margin-top: 14rem;
    }
}

@media (max-width: 1400px) {
    #app {
        margin-top: 14rem;
    }
}

@media (max-width: 1120px) {
    #app {
        margin-top: 11rem;
    }
}

@media (max-width: 1000px) {
    #app {
        margin-top: 10rem;
    }
}

@media (max-width: 870px) {
    #app {
        margin-top: 8rem;
    }
}

@media (max-width: 770px) {
    #app {
        margin-top: 6rem;
    }
}
</style>
